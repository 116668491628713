/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 40px;  /* Reduced from 60px */
	height: 22px; /* Reduced from 34px */
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: .4s;
	border-radius: 34px;
}

.slider:before {
	position: absolute;
	content: "";
	height: 16px; /* Reduced from 26px */
	width: 16px;  /* Reduced from 26px */
	left: 3px;    /* Adjusted from 4px */
	bottom: 3px;  /* Adjusted from 4px */
	background-color: white;
	transition: .4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: #2196F3;
}

input:checked + .slider:before {
	transform: translateX(18px); /* Adjusted from 26px */
}

.theme-toggle-container {
	margin-left: auto;
}

.light-mode .slider {
	background-color: #E1D7C6;
}

.dark-mode .slider {
	background-color: #4A4A4A;
}

.light-mode .slider:before {
	background-color: #CDC2A5;
}

.dark-mode .slider:before {
	background-color: #1E1C18;
}
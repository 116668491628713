/* Breadcrumb styles */
.breadcrumb {
	padding: 0.5rem 1rem;
	background-color: var(--breadcrumb-bg, #f8f9fa);
	border-radius: 0.25rem;
	margin-bottom: 1rem;
}

.breadcrumb-list {
	display: flex;
	list-style: -webkit-gradient();
	margin: 0;
}

.breadcrumb-item {
	font-size: 1.5rem;
}

.breadcrumb-item:not(:last-child)::after {
	content: ">";
	margin: 0 0.5rem;
	color: var(--breadcrumb-separator-color, #6c757d);
}

.breadcrumb-link {
	color: var(--breadcrumb-link-color, #007bff);
	text-decoration: none;
}

.breadcrumb-link:hover {
	text-decoration: underline;
}
.sidebar {
	background-color: var(--sidebar-bg);
	color: var(--text-color);
	width: 175px;
	margin-top: 10px;
	padding: 1rem;
	transition: all 0.3s ease;
	position: relative;
}

.collapse-button {
	position: relative;
	top: 10px;
	width: 23px;
	height: 30px;
	padding: 0;
	font-size: 1rem;
	background-color: var(--sidebar-bg);
	color: var(--text-color);
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	transition: all 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.sidebar.collapsed {
	width: 0;
	padding: 0;
	overflow: hidden;
}

.sidebar.collapsed+.collapse-button {
	left: 0;
}

.sidebar-content {
	opacity: 1;
	transition: opacity 0.3s ease;
}

.sidebar.collapsed .sidebar-content {
	opacity: 0;
}

.sidebar ul {
	list-style-type: none;
	padding: 0 0 0 1rem;
}

.sidebar li {
	margin-bottom: 0.5rem;
}

.sidebar a {
	text-decoration: none;
	color: var(--link-color);
}

.sidebar a:hover {
	text-decoration: underline;
}
/* TODO: Remove this */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

:root {
	font-size: 16px; /* Base font size */
}

body, html {
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	font-size: 1rem; /* 16px */
}

.app {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100vw;
	overflow-x: hidden;
}

.content {
	display: flex;
	flex: 1;
}

header {
	background-color: var(--header-bg);
	padding: .5rem 1rem;
	align-items: center;
	font-family: 'Orbitron', 'Times New Roman';
	font-size: clamp(0.9rem, 2vw, 1.2rem);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	width: 100%;
	box-sizing: border-box; /* Ensure padding is included in the width */
}

.header-right {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.about-button {
	background-color: var(--sidebar-bg);
	color: var(--text-color);
	padding: 0.25rem .6rem;
	border: none;
	border-radius: 4px;
	font-size: clamp(0.8rem, 1.5vw, 1rem);
	transition: background-color 0.3s ease, transform 0.2s ease;
}

.about-button:hover {
	background-color: var(--sidebar-hover-bg);
	cursor: pointer;
	transform: translateY(-2px);
	transition: background-color 0.3s ease;
}


main {
	flex: auto;
	transition: margin-left 0.3s ease;
}

footer {
	background-color: var(--footer-bg);
	color: var(--text-color);
	text-align: center;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
	padding: 1rem;
	width: 100%;
	font-size: clamp(0.7rem, 1.5vw, 0.9rem);
}

@media screen and (max-width: 768px) {
	footer {
		padding: 0.5rem;
		font-size: 0.7rem;
	}
	:root {
		font-size: 14px; 
	}
}

.header {
	background-color: var(--header-bg);
	padding: .5rem 1rem;
	font-family: 'Orbitron', 'Times New Roman';
	font-size: clamp(0.9rem, 2vw, 1.2rem);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	width: 100%;
	box-sizing: border-box; /* Ensure padding is included in the width */
}

.flex-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	box-sizing: border-box; /* Ensure padding is included in the width */
}

.header-right {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.about-button {
	background-color: var(--sidebar-bg);
	color: var(--text-color);
	padding: 0.25rem .6rem;
	border: none;
	border-radius: 4px;
	font-size: clamp(0.8rem, 1.5vw, 1rem);
	transition: background-color 0.3s ease, transform 0.2s ease;
}

.about-button:hover {
	background-color: var(--sidebar-hover-bg);
	cursor: pointer;
	transform: translateY(-2px);
	transition: background-color 0.3s ease;
}